import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import Burger from "./Burger";
import { UserContext } from "../provider/usercontextprovider";
import logo from "../images/detectwise_questmark_tier1-logo_color_white-sRGB.svg"
import { useAuthenticator } from '@aws-amplify/ui-react'
import * as Button from "./button.module.css"

const Header = ({ siteTitle }) => {

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [userContext, setUserContext] = useContext(UserContext)

  const signOutDisplay = userContext.username == null ? 'none' : 'initial';

  let children = [
    <li key="home"><Link to="/" >Home</Link></li>,
    <li key="qrcode" style={{display: signOutDisplay}}><Link to="/user-qrcode">Identification Key</Link></li>,
    <li key="appointment" style={{display: signOutDisplay}}><Link to="/appointment">Book Appointment</Link></li>,
    <li key="my_observations" style={{display: signOutDisplay}}><Link to="/observations">Observations</Link></li>,
    // <li key="smarthealthcard" style={{display: signOutDisplay}}><Link to="/smart-health-card">Health Card</Link></li>,
  ];
  if(userContext.username){
    let groups = userContext.signInUserSession.accessToken.payload["cognito:groups"];
    // if(groups.includes('TenantEnroller')){
    //   children.push(<li key="enroller"><Link to="/enroller" >Enroll</Link></li>)
    // }
    if(groups.includes('Observer')){
      children.push(<li key="observe" style={{display: signOutDisplay}}><Link to="/observe">Observe</Link></li>)
    }
    if(groups.includes('Manager') || groups.includes('TenantAdmin')){
      children.push(<li key="managerpage"><Link to="/manager" >Manage</Link></li>)
    }
  }
  children.push(<li key="signout" style={{display: signOutDisplay}}><button className={Button.button} onClick={signOut}>Sign Out</button></li>);

  return (
  <header
    style={{
      background: `#081D31`,
      marginBottom: `1.45rem`,
      display: `flex`,
      justifyContent: `space-between`
    }}
  >
    <div
      style={{
        maxWidth: 270,
        padding: `0.45rem 0.087rem`,
        flex: 1
      }}
    >
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img src={logo} style={{margin: '10px 0px 0px 10px'}}/>
        </Link>
    </div>
    <div style={{
          // margin: `1rem`
        }}
      >
        <Burger children={children}/>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
