import React, { useState, createContext } from 'react';

export const LoadMaskContext = createContext([
    false,
    ()=>{}
])

export const LoadMaskProvider = props => {
  const loadMaskHook = useState(false);

  return (
    <LoadMaskContext.Provider value={loadMaskHook}>
      {props.children}
    </LoadMaskContext.Provider>
  )
};
