/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import Amplify from "aws-amplify"
import awsConfig from "./src/aws-exports"
import React from 'react';
import { UserContextProvider } from './src/provider/usercontextprovider';
import { LoadMaskProvider } from './src/provider/loadmaskprovider';
import LoadMask from './src/components/loadmask';
Amplify.configure(awsConfig)

export const wrapRootElement = ({ element }) => (
    <UserContextProvider>
        <LoadMaskProvider>
            {element}
            <LoadMask/>
        </LoadMaskProvider>
    </UserContextProvider>
  );
;