import React, { useState, createContext } from 'react';

export const UserContext = createContext([
    {username:null},
    ()=>{}
])

export const AuthContext = createContext({
  auth:null,
  updateContext:()=>{}
})

export const UserContextProvider = props => {
  const userInfoHook = useState({username:null});
  const authStateHook = useState({});

  return (
    <UserContext.Provider value={userInfoHook}>
      <AuthContext.Provider value={authStateHook}>
      {props.children}
      </AuthContext.Provider>
    </UserContext.Provider>
  )
};
