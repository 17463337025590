exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anonymizedlookup-js": () => import("./../../../src/pages/anonymizedlookup.js" /* webpackChunkName: "component---src-pages-anonymizedlookup-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-enroller-index-js": () => import("./../../../src/pages/enroller/index.js" /* webpackChunkName: "component---src-pages-enroller-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitation-js": () => import("./../../../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-manager-add-medical-test-js": () => import("./../../../src/pages/manager/add-medical-test.js" /* webpackChunkName: "component---src-pages-manager-add-medical-test-js" */),
  "component---src-pages-manager-index-js": () => import("./../../../src/pages/manager/index.js" /* webpackChunkName: "component---src-pages-manager-index-js" */),
  "component---src-pages-manager-invite-js": () => import("./../../../src/pages/manager/invite.js" /* webpackChunkName: "component---src-pages-manager-invite-js" */),
  "component---src-pages-manager-medicallookup-js": () => import("./../../../src/pages/manager/medicallookup.js" /* webpackChunkName: "component---src-pages-manager-medicallookup-js" */),
  "component---src-pages-manager-observations-js": () => import("./../../../src/pages/manager/observations.js" /* webpackChunkName: "component---src-pages-manager-observations-js" */),
  "component---src-pages-manager-orders-index-js": () => import("./../../../src/pages/manager/orders/index.js" /* webpackChunkName: "component---src-pages-manager-orders-index-js" */),
  "component---src-pages-manager-orders-new-js": () => import("./../../../src/pages/manager/orders/new.js" /* webpackChunkName: "component---src-pages-manager-orders-new-js" */),
  "component---src-pages-manager-preferences-js": () => import("./../../../src/pages/manager/preferences.js" /* webpackChunkName: "component---src-pages-manager-preferences-js" */),
  "component---src-pages-manager-results-js": () => import("./../../../src/pages/manager/results.js" /* webpackChunkName: "component---src-pages-manager-results-js" */),
  "component---src-pages-manager-tenant-analytic-edit-js": () => import("./../../../src/pages/manager/tenant-analytic-edit.js" /* webpackChunkName: "component---src-pages-manager-tenant-analytic-edit-js" */),
  "component---src-pages-manager-tenant-js": () => import("./../../../src/pages/manager/tenant.js" /* webpackChunkName: "component---src-pages-manager-tenant-js" */),
  "component---src-pages-manager-user-details-js": () => import("./../../../src/pages/manager/user-details.js" /* webpackChunkName: "component---src-pages-manager-user-details-js" */),
  "component---src-pages-manager-user-js": () => import("./../../../src/pages/manager/user.js" /* webpackChunkName: "component---src-pages-manager-user-js" */),
  "component---src-pages-observations-js": () => import("./../../../src/pages/observations.js" /* webpackChunkName: "component---src-pages-observations-js" */),
  "component---src-pages-observe-index-js": () => import("./../../../src/pages/observe/index.js" /* webpackChunkName: "component---src-pages-observe-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-reportdownload-js": () => import("./../../../src/pages/reportdownload.js" /* webpackChunkName: "component---src-pages-reportdownload-js" */),
  "component---src-pages-user-qrcode-js": () => import("./../../../src/pages/user-qrcode.js" /* webpackChunkName: "component---src-pages-user-qrcode-js" */)
}

