import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index:19;
  margin-bottom: 0px;
  align-items:center;
  height:100%;
  li {
    padding: 14px 10px;
    color: #c2c2c2;
    margin-bottom: 0px;
    text-align:center;
  }
  li>a{
    color: #c2c2c2;
    text-decoration: none;
  }
  li>a:hover{
    color: #fff;
    text-decoration: underline;
  }
  @media (max-width: ${props => props.maxWidth}) {
    flex-flow: column nowrap;
    background-color: rgb(8, 29, 49);
    transform:${props => props.open ?  'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: auto;
    width: 100vw;
    margin-top: 78px;
    // until we can correct the initial transition we've disabled this
    // transition: transform 0.3s ease-in-out;
    align-items:initial;
    position:${props => props.open ? 'absolute' : 'fixed'};
    li {
      border-style: solid hidden hidden hidden;
      border-width: 1px;
      border-color: #3f3f3f;

    }
  }
`;

const RightNav = (props) => {
  return (
    <Ul open={props.open} maxWidth={props.maxWidth}>
      {props.children}
    </Ul>
  )
}

export default RightNav