import React, { useState } from 'react';
import ReactModal from 'react-modal';
import * as modalStyles from './modalstyles.module.css';
import * as buttonStyles from './button.module.css';

const TermsOfService = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }
    return (
        <div>
            <p style={{margin: "0"}}>By using DetectWise, you agree to be bound to it's <a href="#" onClick={openModal} style={{color: "white"}}>Terms of Service</a>.</p>
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Parsons Terms of Service"
                className={modalStyles.modal}
                overlayClassName={modalStyles.overlay}
            >
                <div className={modalStyles.modalContent}>
                    <div className={modalStyles.modalHeader}>
                        <h2>Parsons DetectWise Terms, Conditions, and Privacy Policy</h2>
                    </div>
                    <div className={modalStyles.modalBody}>
                        <p>At Parsons, we are committed to protecting the privacy and security of visitors and users of our DetectWise web app. This privacy policy (“Privacy Policy”) will tell you what information we collect, how it is used and your options as you interact with DetectWise. By using DetectWise, you agree to this policy. Please read the following carefully before using DetectWise.</p>
                        <p><strong>“Protected health information” as defined under the Health Insurance Portability & Accountability Act and related regulations (collectively referred to as “HIPAA”) is separate and not subject to these terms and conditions.</strong></p>
                        <h3>Your Consent</h3>
                        <p>By using DetectWise, you consent to the collection, use and sharing of information as described in this Privacy Policy. By providing us with an email address, you agree that we may use your email address to communicate with you regarding the information entered here.</p>
                        <h3>Information That We Collect</h3>
                        <p>DetectWise will collect your name, preferred phone number, and email address. You will also be asked questions about your exposure to and symptoms associated with COVID-19. You may also be asked about your travel outside of the United States.</p>
                        <p>If you submit to DetectWise any personal information relating to other people, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy. By submitting personal information, you grant Parsons the right to transmit, monitor, retrieve, store and use your information in connection with the operation of DetectWise.</p>
                        <h3>Data Security</h3>
                        <p>Parsons is committed to protecting the privacy of the personal information you provide to us via DetectWise so that we can make sure it remains as secure as possible. Accordingly, we use reasonable efforts to prevent unauthorized access. We use a secure firewall and a security infrastructure that protects the integrity and privacy of personal information submitted to us via DetectWise. As an additional security measure, your personal information is also encrypted during transmission using appropriate technology including Transport Layer Security. This is an industry standard. (Encryption is a common method used to protect transmission of sensitive data across the Internet.)</p>
                        <p>Although we work hard to protect your personal information, Parsons cannot guarantee the security of any information you transmit to us through online applications, and you do so at your own risk. Depending on the nature of the inquiry, your communication could be discarded or archived. If you wish, you can contact us instead by telephone at the number provided at Parsons.com.</p>
                        <h3>Cookies, Tracking and Internet-based Advertising</h3>
                        <p>The DetectWise website uses “cookies,” tracking pixels and related technologies. These can be set by Parsons or by our Service Providers.</p>
                        <p>Cookies are small data files that online services can store on, and retrieve from, a user’s computer or mobile device through the user’s web browser. The information is stored and retrievable either for the duration of your visit (known as “session cookies”), or until some later point in time set by DetectWise setting the cookie (known as “persistent cookies”).</p>
                        <p>Most internet browsers accept cookies by default, but you can modify your browser preferences to block or restrict cookies if you do not want your web browsing activity tracked. There are a variety of tools publicly available to manage cookies and similar technologies that collect information related to your use of DetectWise.</p>
                        <p>We are not responsible for the effectiveness or compliance of third party opt-out mechanisms or programs. Please note that if you delete your cookies or upgrade your browser after having opted out, you will need to opt out again. Further, if you use multiple browsers or devices you will need to opt out on each browser or device. You can access the information on DetectWise without enabling cookies in your browser but disabling cookies may result in a diminished ability to take advantage of the services and related informational content on DetectWise.</p>
                        <p>We use web analytics and tag management services and tools provided by Google and other third parties to collect certain information relating to your use of DetectWise. You can find out more about how Google uses data when you visit DetectWise by visiting “How Google uses data when you use our partners' sites or apps.”</p>
                        <p>Our Service Providers may acquire additional information about your activity on DetectWise, including pages you visit, access times, visit duration, how you arrived at DetectWise and your IP address. An IP address is a number that identifies a device connected to the Internet. For most devices, the IP address changes on at least a weekly basis. Our Service Providers may also acquire device identifiers and specific information about the browser you use. In some cases, this information may be unique to you.</p>
                        <h3>How We Use This Information</h3>
                        <p>Parsons may use your information to:</p>
                        <ul>
                            <li>Help us improve the services we offer</li>
                            <li>Reach out to you if we need to follow-up because of your answers to DetectWise</li>
                            <li>Improve our DetectWise offerings</li>
                            <li>Improve customer service</li>
                            <li>Communicate changes to our Privacy Policy or terms of use</li>
                        </ul>
                        <h3>Third-Party Vendors/Content Providers</h3>
                        <p>Parsons shares information with third parties as required by law or in order to provide the services requested. Parsons engages reputable third-party vendors to help us manage DetectWise, provide content and information, and interact better with our patients and visitors.</p>
                        <h3>Email Information</h3>
                        <p>Please do not provide personal information via email to us unless it is through a secure channel (these will be marked, as secure online forms, for example). Remember that email messages, unless encrypted while in transit, can be viewed by other Internet users. If you have personal information to communicate, please use the appropriate secure online form or contact the necessary party using traditional means such as phone or mail.</p>
                        <h3>Links to External Applications</h3>
                        <p>This online Privacy Policy applies only to DetectWise. Please be aware that this Privacy Policy does not apply to any other applications. We encourage you to read the Privacy Policy on any other applications before providing them with personal information.</p>
                        <h3>Changes to This Policy</h3>
                        <p>Parsons has the right to change or update this Privacy Policy from time to time without notice, so please review it periodically to keep informed of any changes. If you have questions about this Privacy Policy or concerns about how we collect, use or protect your personal information, please contact us at www.Parsons.com.</p>
                    </div>
                    <div className={modalStyles.modalFooter}>
                        <div style={{ float: "right" }}>
                            <button className={buttonStyles.button} onClick={closeModal}>Close</button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}

export default TermsOfService;