import React, { useState } from 'react'
import styled from 'styled-components'
import RightNav from './RightNav';

const maxWidth = '808px'
const StyledBurger = styled.div`
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 2px;
    right: 20px;
    z-index: 20;
    display: none;
    margin-top:50%;
    margin-bottom:50%;
    @media (max-width: ${maxWidth}) {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
    div {
        width: 2rem;
        height: 0.25rem;
        background-color: #fff;
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;
        cursor: pointer;
        &:nth-child(1) {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        &:nth-child(2) {
            // transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({ open }) => open ? 0 : 1};
        }
        &:nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`;

export const Burger = (props) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </StyledBurger>
            <RightNav open={open} children={props.children} maxWidth={maxWidth}/>
        </>
    )
}

export default Burger