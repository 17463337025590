import React, { useContext } from "react"
import * as styles from "../components/loadmask.module.css"
import { LoadMaskContext } from '../provider/loadmaskprovider'

export const LoadMask = (props) => {
    const [isLoading, setIsLoading] = useContext(LoadMaskContext)

    return (
        <div style={{ visibility: isLoading ? 'visible': 'hidden'}} className={styles.loadmaskOverlay}>
            <div className={styles.loader}></div>
          </div>
    )
}

export default LoadMask